<template>
  <div class="auth reset-password">
    <div class="reset-password__background"></div>
    <section class="reset-password__form">
      <div
        :class="[
          'reset-password__loader',
          { 'reset-password__loader--step-2': showPasswordField }
        ]"
      ></div>
      <div v-if="validationErrors.length" class="reset-password__errors">
        <div>
          <div
            v-for="(error, errorIndex) in validationErrors"
            :key="`error-${errorIndex}`"
            v-html="error"
          ></div>
        </div>
      </div>

      <div class="reset-password__form-step">
        <img src="../assets/images/logo.svg" :alt="`${$companyName} logo`" />

        <h2 class="reset-password__form-title">
          {{ $languages[getAppLanguage].resetPassword.title }}
        </h2>
        <p>
          {{ $languages[getAppLanguage].resetPassword.sub }}
        </p>
        <br />

        <form>
          <label v-if="showPasswordField" for="passwrod">
            <span>
              {{ $languages[getAppLanguage].resetPassword.passwordLabel }}
            </span>
            <input
              type="password"
              v-model="password"
              id="passwrod"
              :placeholder="
                $languages[getAppLanguage].resetPassword.passwordPlaceholder
              "
              autocomplete="true"
            />
          </label>

          <label v-if="showPasswordField" for="passwrod">
            <span>
              {{ $languages[getAppLanguage].resetPassword.passwordRepeatLabel }}
            </span>
            <input
              type="password"
              v-model="passwordRepeat"
              id="passwrod-repeat"
              :placeholder="
                $languages[getAppLanguage].resetPassword
                  .passwordRepeatPlaceholder
              "
              autocomplete="true"
            />
          </label>

          <label v-else for="email">
            <span>
              {{ $languages[getAppLanguage].resetPassword.emailLabel }}
            </span>
            <input
              type="email"
              v-model="email"
              id="email"
              :placeholder="
                $languages[getAppLanguage].resetPassword.emailPlaceholder
              "
            />
          </label>

          <button
            @click.prevent="
              showPasswordField ? validatePassword() : validateEmail()
            "
          >
            {{
              showPasswordField
                ? $languages[getAppLanguage].resetPassword.ctaTextForPassword
                : $languages[getAppLanguage].resetPassword.ctaTextForEmail
            }}
          </button>

          <p>
            <small>
              {{ $languages[getAppLanguage].resetPassword.note1 }}
            </small>
          </p>

          <br />

          <p>
            <small>
              {{ $languages[getAppLanguage].resetPassword.note2 }}
            </small>
          </p>
          <br />

          <div class="reset-password__form-divider"></div>
          <router-link to="/login">
            {{ $languages[getAppLanguage].resetPassword.loginLink }}
          </router-link>
          <div class="reset-password__form-divider"></div>
          <router-link to="/signup">
            {{ $languages[getAppLanguage].resetPassword.signUpLink }}
          </router-link>
          <div class="reset-password__form-divider"></div>
          <br />
          <br />
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ResetPassword',

  data: () => ({
    email: null,
    password: null,
    passwordRepeat: null,
    validationErrors: [],
    showPasswordField: false
  }),

  computed: {
    ...mapGetters(['getAppLanguage'])
  },

  mounted() {
    if (this.$user.uid) this.$router.replace('/document/loading...', () => {})
    else this.checkShowPasswordField()
  },

  methods: {
    ...mapActions(['sendPasswordResetEmailAction', 'resetPasswordAction']),

    /**
     *
     */
    resetValidationErrors() {
      this.validationErrors = []
    },

    /**
     *
     */
    validateEmail() {
      // Clear the errors before we validate again
      this.resetValidationErrors()

      // email validation
      if (!this.email) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].resetPassword.errors.email.empty
        )
      }

      if (/.+@.+/.test(this.email) !== true) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].resetPassword.errors.email
            .invalid
        )
      }

      // when valid then send email
      if (!this.validationErrors.length) {
        this.sendResetPasswordEmail()
      }
    },

    /**
     *
     */
    validatePassword() {
      // Clear the errors before we validate again
      this.resetValidationErrors()

      // password validation
      if (!this.password) {
        this.validationErrors.push('<strong>Password</strong> cannot be empty')
      }

      if (this.password !== this.passwordRepeat) {
        this.validationErrors.push(
          'Both <strong>passwords</strong> must be identical'
        )
      }

      if (/.{6,}/.test(this.password) !== true) {
        this.validationErrors.push(
          '<strong>Password</strong> must be at least 6 characters long'
        )
      }

      // when valid then reset password
      if (!this.validationErrors.length) {
        this.resetPassword()
      }
    },

    /**
     *
     */
    sendResetPasswordEmail() {
      this.sendPasswordResetEmailAction({
        email: this.email
      })
    },

    /**
     *
     */
    checkShowPasswordField() {
      const urlParams = new URLSearchParams(this.$router.currentRoute.fullPath)
      const actionCode = urlParams.get('oobCode')

      if (actionCode) {
        this.showPasswordField = !this.showPasswordField
      }
    },

    /**
     *
     */
    resetPassword() {
      const urlParams = new URLSearchParams(this.$router.currentRoute.fullPath)
      const actionCode = urlParams.get('oobCode')

      if (actionCode) {
        this.showPasswordField = !this.showPasswordField
        this.resetPasswordAction({
          actionCode,
          password: this.password
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-password__loader {
  position: absolute;
  top: 0;
  width: 100%;
  height: var(--size-unit-half);
  background: linear-gradient(
    90deg,
    var(--color-main-dark) 0%,
    var(--color-main-light) 100%
  );

  &--step-2 {
    background: linear-gradient(
      90deg,
      var(--color-main-light) 0%,
      var(--color-main-dark) 100%
    );
  }
}

.reset-password__errors {
  width: 100%;
  max-width: 440px;
  padding-bottom: var(--size-unit-3);
  color: var(--color-error);
}

.reset-password__background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../assets/auth-background-blurred.jpg');
  background-size: cover;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(
      var(--color-brand-darkest-rgb),
      var(--opacity-overlay)
    );
  }
}

.reset-password__form {
  @include form;
}

.reset-password__form-divider {
  margin: var(--size-unit) 0 var(--size-unit-2);
  height: 1px;
  background-color: var(--color-theme-lighter);
}

.reset-password__form-step {
  @include hide-scrollbar;
  overflow-y: scroll;
  width: 100%;
  max-width: 440px;
  height: 100%;

  img {
    margin-bottom: var(--size-unit);
    width: 50%;
    min-width: 180px;
  }

  @include screen-medium {
    height: initial;

    img {
      margin-bottom: var(--size-unit-5);
    }
  }
}

.reset-password__form-title {
  margin: var(--size-unit-2) 0;
}

::-webkit-scrollbar {
  display: none;
}
</style>
